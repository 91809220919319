import React from 'react'
import { formatDate, convertUrlToInnerLink } from 'src/commonFunctions'
import OfficialTag from 'components/atoms/OfficialTag'
import { Link } from 'gatsby'

export default ({ date, isOfficial, linktoindex, genre, share }) => (
  <div className="article_header">
    <div className="icon">
      <img src={genre.IMAGE} lazyload />
    </div>
    <div className="info">
      <div className="basicinfo">
        {!share ? <div className="genre_title">{genre.TITLE}</div> : <div />}
        <div className="date">{formatDate(date)}</div>
        {isOfficial && <OfficialTag isArticle />}
      </div>
      {// 目次へのリンクが設定されていれば連載扱い
      linktoindex && (
        <Link
          className="series_title"
          to={convertUrlToInnerLink(linktoindex.url)}
        >
          {linktoindex.title}
        </Link>
      )}
    </div>
  </div>
)
