import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE, BASE_URL } from 'src/constants'
import { getImageUrl } from 'src/commonFunctions'

export default ({ title, postUrl, ogp }) => (
  <Helmet
    meta={[
      { name: 'description', content: ogp ? ogp.description : SITE_TITLE },
      { property: 'og:title', content: title },
      { property: 'og:type', content: 'blog' },
      { property: 'og:url', content: BASE_URL + postUrl },
      { property: 'og:image', content: getImageUrl(ogp) },
      {
        property: 'og:description',
        content: ogp ? ogp.description : SITE_TITLE,
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@touhou_station' },
      { property: 'twitter:image', content: getImageUrl(ogp) },
      { property: 'fb:app_id', content: '965267267157376' },
    ]}
  />
)
