import React from 'react'
import icon_hatena_bookmark from 'img/hatena_bookmark.svg'
import { intl } from 'src/constants'

export default ({ url, title, size }) => (
  <a
    href={`http://b.hatena.ne.jp/add?mode=confirm&url=${url}&title=${title}`}
    rel="nofollow"
    target="_blank"
  >
    <img
      style={{
        width: `${size}px`,
        height: `${size}px`,
        margin: '0',
      }}
      src={icon_hatena_bookmark}
      alt={intl.getMessage('hatena_bookmark')}
    />
  </a>
)
