import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Ogp from 'components/atoms/Ogp'
import { SITE_TITLE } from 'src/constants'
import { stripLinkToIndex } from 'src/commonFunctions'
import LinkToIndex from 'components/atoms/LinkToIndex'
import ShareCard from 'components/ShareCard'
import { intl } from 'src/constants'
import LightboxModal from 'components/LightboxModal'

const Illustration = ({ pageContext }) => {
  const linktoindex = stripLinkToIndex(pageContext.linkToIndex)
  const authors = pageContext.novelContent.authors
  return (
    <div className="illustration">
      <Helmet title={`${pageContext.title} | ${SITE_TITLE}`} />
      <Ogp
        title={pageContext.title}
        postUrl={pageContext.slug}
        ogp={pageContext.ogpSettings.ogp}
      />
      <h2>{pageContext.title}</h2>
      <div>
        {authors &&
          authors.length &&
          authors.map(author => <h3>{`${author.role}: ${author.name}`}</h3>)}
      </div>
      <LightboxModal srcUrl={pageContext.imgInfo.url} />
      <p>{pageContext.imgInfo.alt}</p>
      <div className="link">
        {pageContext.prevLink ? (
          <Link to={pageContext.prevLink}>
            <p>{intl.getMessage('prev_link')}</p>
          </Link>
        ) : (
          <p className="gray_out_link">{intl.getMessage('prev_link')}</p>
        )}
        <Link to={`/novel/${pageContext.slug}`}>
          <p>{intl.getMessage('to_novel')}</p>
        </Link>
        {pageContext.nextLink ? (
          <Link to={pageContext.nextLink}>
            <p>{intl.getMessage('next_link')}</p>
          </Link>
        ) : (
          <p className="gray_out_link">{intl.getMessage('next_link')}</p>
        )}
      </div>
      {linktoindex ? <LinkToIndex link={linktoindex} /> : <div />}
      <ShareCard
        type={pageContext.type}
        slug={pageContext.slug}
        title={pageContext.title}
        date={pageContext.date}
        tags={pageContext.tags}
        isOfficial={pageContext.isOfficial}
        linkToIndex={linktoindex}
        sharePath={pageContext.sharePath}
      />
    </div>
  )
}

Illustration.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  slug: PropTypes.string,
  sharePath: PropTypes.string,
  novelContent: PropTypes.shape({
    author: PropTypes.string,
    contents: PropTypes.string,
  }),
  ogpSettings: PropTypes.shape({
    ogp: PropTypes.object,
  }),
  imgInfo: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  prevLink: PropTypes.string,
  nextLink: PropTypes.string,
}

export default Illustration
