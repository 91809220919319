import React from 'react'
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from 'react-share'
import HatebuShareButton from 'components/atoms/HatebuShareButton'
import { urlJoin } from 'src/commonFunctions'
import { SITE_TITLE_SHORT, BASE_URL } from 'src/constants'

export default ({ title, sharePath, iconSize = 24 }) => {
  const shareUrl = urlJoin([BASE_URL, sharePath])
  const shareTitle = `${title} - ${SITE_TITLE_SHORT}`
  return (
    <div className="share_content">
      <div className="share_label">SHARE</div>
      <div className="share_button share_twitter">
        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={iconSize} />
        </TwitterShareButton>
      </div>
      <div className="share_button share_facebook">
        <FacebookShareButton url={shareUrl} quote={shareTitle}>
          <FacebookIcon size={iconSize} />
        </FacebookShareButton>
      </div>
      <div className="share_button share_line">
        <LineShareButton url={shareUrl} title={shareTitle}>
          <LineIcon size={iconSize} />
        </LineShareButton>
      </div>
      <div className="share_button share_hatebu">
        <HatebuShareButton size={iconSize} url={shareUrl} title={shareTitle} />
      </div>
    </div>
  )
}
