import React from 'react'
import { stripOfficial } from 'src/commonFunctions'
import ArticleHeader from 'components/ArticleHeader'
import ShareContent from 'components/ShareContent'
import { convertArticleType2menus, urlJoin } from 'src/commonFunctions'

export default ({ type, title, date, linkToIndex, sharePath, isOfficial }) => (
  <div className="share_card">
    <ArticleHeader
      date={date}
      isOfficial={stripOfficial(isOfficial)}
      linktoindex={linkToIndex}
      genre={convertArticleType2menus(type)}
      share
    />
    <h3 className="share_title">{title}</h3>
    <ShareContent title={title} sharePath={sharePath} iconSize={36} />
  </div>
)
