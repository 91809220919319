import React from 'react'
import { Link } from 'gatsby'
import iconPickup from 'img/icon_menu_pickup.png'
import {
  convertUrlToInnerLink,
  stripLinkToIndexToURL,
} from 'src/commonFunctions'
import { intl } from 'src/constants'

export default ({ link }) => {
  if (!link) {
    return <div />
  }
  const url = stripLinkToIndexToURL(link)
  return (
    <div className="link_to_index">
      <Link to={convertUrlToInnerLink(url)}>
        <img lazyload src={iconPickup} alt={intl.getMessage('serial')} />
        <span>
          {link.title ? link.title : link.linktoindex.title}{' '}
          {intl.getMessage('to_table_of_contents')}
        </span>
      </Link>
    </div>
  )
}
